var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CNavbar',{staticClass:"p3 shadow nav-mobile",attrs:{"sticky":true,"expandable":"md","type":"dark"}},[_c('CToggler',{attrs:{"in-navbar":"","id":"toogleButton"},on:{"click":function($event){_vm.collapsed = !_vm.collapsed}}},[_c('font-awesome-icon',{attrs:{"icon":"bars","size":"lg"}})],1),_c('CNavbarBrand',{staticClass:"ml-auto mr-auto",attrs:{"href":"#"},on:{"click":function($event){return _vm.toOrderPage()}}},[_c('img',{staticStyle:{"height":"30px"},attrs:{"src":require("@/assets/images/d-dot-logo.svg"),"alt":""}})]),(_vm.$store.state.isShowCloseButtonChatOrder)?_c('div',{staticClass:"panel-close-profile-order pb-1 pt-2"},[_c('span',{staticClass:"cursor-pointer my-auto",on:{"click":function($event){return _vm.$store.commit('UPDATE_SHOW_CLOSE_BUTTON_CHATORDER', false)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times']}}),_vm._v(" ปิด ")],1)]):_vm._e(),_c('CCollapse',{attrs:{"show":_vm.collapsed,"navbar":""}},[_c('CNavbarNav',{staticClass:"ml-auto my-2"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.goToChat()}}},[_c('font-awesome-icon',{staticClass:"mr-2 color-primary",attrs:{"icon":"comments"}}),_vm._v(" Chat ")],1),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"light"},on:{"click":function($event){return _vm.goToDOrder()}}},[_c('font-awesome-icon',{staticClass:"color-primary",attrs:{"icon":"store"}}),_vm._v(" D Order ")],1),(0)?_c('div',[(
            _vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied'
            //  && isStore == 1
            //  && isShowHeaderTab
          )?_c('div',{staticClass:"ml-md-2"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.setShowModalExport(true)}}},[_c('font-awesome-icon',{staticClass:"mr-2 color-primary",attrs:{"icon":"download"}}),_vm._v("Export Sheet ")],1)],1):_vm._e(),(
            _vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied'
            //  && isShowHeaderTab
          )?_c('div',{staticClass:"ml-md-2"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.setShowModalImport(true)}}},[_c('font-awesome-icon',{staticClass:"mr-2 color-primary",attrs:{"icon":"upload"}}),_vm._v("Import Sheet ")],1)],1):_vm._e()]):_vm._e(),(
          _vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied'
          //  && isShowHeaderTab
        )?_c('div',{staticClass:"ml-md-2"},[_c('b-dropdown',{attrs:{"id":"dropdown-brand","text":_vm.selectedBrandName,"variant":"light","right":""}},_vm._l((_vm.brandLists),function(brand,bindex){return _c('b-dropdown-item',{key:bindex,class:{ active: _vm.brandId === ("" + (brand.id)) },on:{"click":function($event){return _vm.onBrandChange(brand)}}},[_c('div',[_vm._v(" "+_vm._s(brand.name)+" ")])])}),1)],1):_vm._e(),(_vm.$route.path !== '/login' && _vm.$route.path !== '/AccessDenied')?_c('div',{staticClass:"ml-md-2"},[_c('b-dropdown',{attrs:{"id":"dropdown-logout","variant":"light","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-avatar',{staticClass:"user-icon px-2 py-1"}),_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.username))])]},proxy:true}],null,false,63638836)},[_c('b-dropdown-item',{on:{"click":_vm.handleLogout}},[_c('font-awesome-icon',{staticClass:"mr-2 color-primary",attrs:{"icon":"sign-out-alt"}}),_vm._v("Log out ")],1)],1)],1):_vm._e()],1)],1),(_vm.forceRefresh)?[_c('div',[_c('SelectBrand',{attrs:{"isShow":_vm.isShowModalBrand,"onBrandChange":_vm.onBrandChange},on:{"setShowModalBrand":_vm.setShowModalBrand}}),_c('ModalImport',{attrs:{"isShow":_vm.isShowModalImport,"uploadTracking":_vm.uploadTracking,"shippingLists":_vm.shippingLists},on:{"setShowModalImport":_vm.setShowModalImport}}),_c('ModalExportJobsheet',{attrs:{"isShow":_vm.isShowModalExport},on:{"setShowModalExport":_vm.setShowModalExport}}),_c('b-modal',{attrs:{"id":"modal-1","hide-footer":"","hide-header":""},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},[_c('div',{staticClass:"m-3"},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.resultMessage))]),_vm._l((_vm.errorLists),function(item,index){return _c('small',{key:index + 'error',staticClass:"text-danger"},[_vm._v(" "+_vm._s(item)+" ")])})],2)])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }